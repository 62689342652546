import Vue from "vue";
import { translate } from "@/utils/translate";

export default {
    namespaced: true,
    actions: {
        async getRatingCategories(context, queryParams) {
            const { mp, metric, fbs, date, date2, size } = queryParams;
            let { data } = await Vue.api.get("/rating/getRatingCategories", {
                params: {
                    mp,
                    metric,
                    fbs,
                    date,
                    date2,
                    size,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            data.items.forEach((item) => {
                let categories = item.c_name_path.split("/");
                categories = categories.map((category) => {
                    return translate(
                        category.trim(),
                        context.rootState.chineseDictionary
                    );
                });
                item.c_name_path = categories.join(" / ");
            });
            return data;
        },
        async getRatingProducts(context, queryParams) {
            const { mp, metric, fbs, date, date2, size } = queryParams;
            const { data } = await Vue.api.get("/rating/getRatingProducts", {
                params: {
                    mp,
                    metric,
                    fbs,
                    date,
                    date2,
                    size,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            data.items.forEach((item) => {
                let words = item.title.split(" ");
                words = words.map((category) => {
                    return translate(
                        category.trim(),
                        context.rootState.chineseDictionary
                    );
                });
                item.title = words.join(" ");
            });
            return data;
        },
        async getNewProducts(context, queryParams) {
            const { mp, metric, days, fbs, date, date2, size } = queryParams;
            const { data } = await Vue.api.get("/rating/getNewProducts", {
                params: {
                    mp,
                    metric,
                    days,
                    fbs,
                    date,
                    date2,
                    size,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getRatingBrands(context, queryParams) {
            const { mp, metric, fbs, date, date2, size } = queryParams;
            const { data } = await Vue.api.get("/rating/getRatingBrands", {
                params: {
                    mp,
                    metric,
                    fbs,
                    date,
                    date2,
                    size,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getRatingSellers(context, queryParams) {
            const { mp, metric, fbs, date, date2, size } = queryParams;
            const { data } = await Vue.api.get("/rating/getRatingSellers", {
                params: {
                    mp,
                    metric,
                    fbs,
                    date,
                    date2,
                    size,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            data.items.forEach((item) => {
                let words = item.seller.split(" ");
                words = words.map((category) => {
                    return translate(
                        category.trim(),
                        context.rootState.chineseDictionary
                    );
                });
                item.seller = words.join(" ");
            });
            return data;
        },

        async getUpsDownsCategories(context, queryParams) {
            const { mp, metric, type, fbs, date, date2, date3, date4, size } =
                queryParams;
            const { data } = await Vue.api.get(
                "/rating/getUpsDownsCategories",
                {
                    params: {
                        mp,
                        metric,
                        type,
                        fbs,
                        date,
                        date2,
                        date3,
                        date4,
                        size,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getUpsDownsProducts(context, queryParams) {
            const { mp, metric, type, fbs, date, date2, date3, date4, size } =
                queryParams;
            const { data } = await Vue.api.get("/rating/getUpsDownsProducts", {
                params: {
                    mp,
                    metric,
                    type,
                    fbs,
                    date,
                    date2,
                    date3,
                    date4,
                    size,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getUpsDownsBrands(context, queryParams) {
            const { mp, metric, type, fbs, date, date2, date3, date4, size } =
                queryParams;
            const { data } = await Vue.api.get("/rating/getUpsDownsBrands", {
                params: {
                    mp,
                    metric,
                    type,
                    fbs,
                    date,
                    date2,
                    date3,
                    date4,
                    size,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getUpsDownsSellers(context, queryParams) {
            const { mp, metric, type, fbs, date, date2, date3, date4, size } =
                queryParams;
            const { data } = await Vue.api.get("/rating/getUpsDownsSellers", {
                params: {
                    mp,
                    metric,
                    type,
                    fbs,
                    date,
                    date2,
                    date3,
                    date4,
                    size,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        async getConstructorCategories(context, queryParams) {
            const { mp, metric, date, date2, size, fbs } = queryParams;
            const { data } = await Vue.api.get(
                "/rating/getConstructorCategories",
                {
                    params: {
                        mp,
                        metric,
                        date,
                        date2,
                        size,
                        fbs,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getConstructorProducts(context, queryParams) {
            const { mp, metric, date, date2, size, fbs } = queryParams;
            const { data } = await Vue.api.get(
                "/rating/getConstructorProducts",
                {
                    params: {
                        mp,
                        metric,
                        date,
                        date2,
                        size,
                        fbs,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getConstructorBrands(context, queryParams) {
            const { mp, metric, date, date2, size, fbs } = queryParams;
            const { data } = await Vue.api.get("/rating/getConstructorBrands", {
                params: {
                    mp,
                    metric,
                    date,
                    date2,
                    size,
                    fbs,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getConstructorSellers(context, queryParams) {
            const { mp, metric, date, date2, size, fbs } = queryParams;
            const { data } = await Vue.api.get(
                "/rating/getConstructorSellers",
                {
                    params: {
                        mp,
                        metric,
                        date,
                        date2,
                        size,
                        fbs,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
    },
};
