import Vue from "vue";
export default {
    namespaced: true,
    actions: {
        async send(ctx, { name, email_address, phone_number, message }) {
            const { data: feedback } = await Vue.api.post("/feedback/send", {
                name,
                email_address,
                phone_number,
                message,
            });
            return feedback;
        },
    },
};
