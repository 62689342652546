import { plural } from "./numbers";

export const ENTITIES = {
    category: {
        // единств, именительный падеж, кто? что?
        one: "类别",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "类别",
        // единств, дательный падеж, кому? чему?
        oneDative: "类别",
        // единств, винительный падеж, кого? что?
        oneAccusative: "类别",
        // множ, именительный падеж, кто? что?
        many: "类别",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "类别",
        // множ, дательный падеж, кому? чему?
        manyDative: "类别",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "female",
        metrics: {
            sold: {
                title: "销售",
                dative: "销售",
                color: "red",
                type: "sales",
            },
            revenue: {
                title: "营收",
                dative: "营收",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            products: {
                title: "货物",
                dative: "货物",
                color: "blue",
            },
            products_with_sales: {
                title: "销售货物",
                dative: "销售货物",
                percentOf: "products",
                color: "lightgreen",
                columnWidth: 140,
            },
            revenue_per_product: {
                title: "平均支票",
                dative: "平均支票",
                color: "orange",
                type: "money",
            },
            sellers: {
                title: "卖方",
                dative: "卖方",
                color: "fiolet",
                columnWidth: 140,
            },
            sellers_with_sales: {
                title: "有销售的卖家",
                dative: "有销售的卖家",
                percentOf: "sellers",
                color: "pink",
                columnWidth: 140,
            },
            brands: {
                title: "品牌",
                dative: "品牌",
                color: "green",
            },
            brands_with_sales: {
                title: "有销量的品牌",
                dative: "有销量的品牌",
                percentOf: "brands",
                color: "brown",
                columnWidth: 140,
            },
            avg_sold: {
                title: "每日平均销售额",
                dative: "每日平均销售额",
                type: "sales",
                color: "gray",
                columnWidth: 140,
            },
            avg_revenue: {
                title: "平均每日收入",
                dative: "平均每日收入",
                type: "money",
                color: "gray",
                columnWidth: 140,
            },
        },
    },
    shopWb: {
        // единств, именительный падеж, кто? что?
        one: "店铺",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "店铺",
        // единств, дательный падеж, кому? чему?
        oneDative: "店铺",
        // единств, винительный падеж, кого? что?
        oneAccusative: "店铺",
        // множ, именительный падеж, кто? что?
        many: "店铺",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "店铺",
        // множ, дательный падеж, кому? чему?
        manyDative: "店铺",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            total_orders: {
                title: "订单金额",
                dative: "订单金额",
                color: "red",
                type: "sales",
            },
            sum_orders: {
                title: "订单金额",
                dative: "订单金额",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            total_sales: {
                title: "销售数量",
                dative: "销售数量",
                color: "blue",
                type: "sales",
            },
            sum_finished_price_sales: {
                title: "销售金额",
                dative: "销售金额",
                color: "lightgreen",
                type: "money",
                columnWidth: 140,
            },
            total_return_sales: {
                title: "退货数量",
                dative: "退货数量",
                color: "orange",
                type: "sales",
                columnWidth: 140,
            },
            sum_finished_price_return_sales: {
                title: "退款金额",
                dative: "退款金额",
                color: "fiolet",
                type: "money",
                columnWidth: 140,
            },
            sum_stocks: {
                title: "仓库资本总额",
                dative: "仓库资本总额",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            sum_stocks_cost_price: {
                title: "仓库成本",
                dative: "仓库成本",
                color: "green",
                type: "money",
            },
            sum_comission_sales: {
                title: "佣金金额",
                dative: "佣金金额",
                color: "brown",
                type: "money",
            },
            sum_revenue_sales: {
                title: "营收",
                dative: "营收",
                type: "money",
                color: "red",
                columnWidth: 140,
            },
        },
    },
    shopOzon: {
        // единств, именительный падеж, кто? что?
        one: "店铺",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "店铺",
        // единств, дательный падеж, кому? чему?
        oneDative: "店铺",
        // единств, винительный падеж, кого? что?
        oneAccusative: "店铺",
        // множ, именительный падеж, кто? что?
        many: "店铺",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "店铺",
        // множ, дательный падеж, кому? чему?
        manyDative: "店铺",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            total_orders: {
                title: "订单金额",
                dative: "订单金额",
                color: "red",
                type: "sales",
            },
            sum_price_orders: {
                title: "订单数量",
                dative: "订单数量",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            total_sales: {
                title: "销售数量",
                dative: "销售数量",
                color: "blue",
                type: "sales",
            },
            sum_price_sales: {
                title: "销售金额",
                dative: "销售金额",
                color: "lightgreen",
                type: "money",
                columnWidth: 140,
            },
            total_return_sales: {
                title: "退货数量",
                dative: "退货数量",
                color: "orange",
                type: "sales",
                columnWidth: 140,
            },
            sum_price_return_sales: {
                title: "退款金额",
                dative: "退款金额",
                color: "fiolet",
                type: "money",
                columnWidth: 140,
            },
            sum_stocks: {
                title: "仓库资本总额",
                dative: "仓库资本总额",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            sum_stocks_cost_price: {
                title: "仓库成本",
                dative: "仓库成本",
                color: "green",
                type: "money",
            },
            sum_comission_sales: {
                title: "佣金金额",
                dative: "佣金金额",
                color: "brown",
                type: "money",
            },
            sum_revenue_sales: {
                title: "营收",
                dative: "营收",
                type: "money",
                color: "red",
                columnWidth: 140,
            },
        },
    },
    project: {
        // единств, именительный падеж, кто? что?
        one: "项目",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "项目",
        // единств, дательный падеж, кому? чему?
        oneDative: "项目",
        // единств, винительный падеж, кого? что?
        oneAccusative: "项目",
        // множ, именительный падеж, кто? что?
        many: "项目",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "项目",
        // множ, дательный падеж, кому? чему?
        manyDative: "项目",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            queries: {
                title: "要求",
                dative: "要求",
                id: "queries",
                color: "pink",
                // columnUnit: false,
            },
            products: {
                title: "产品",
                dative: "产品",
                id: "products",
                color: "pink",
                // columnUnit: false,
            },
            top1000: {
                title: "职位要求",
                dative: "职位要求",
                color: "green",
                columnWidth: 140,
            },
            avg_position: {
                title: "中间位置",
                dative: "中间位置",
                color: "blue",
                columnWidth: 140,
            },
            top4: {
                title: "前四名",
                dative: "前四名",
                color: "lightgreen",
                columnWidth: 140,
            },
            top12: {
                title: "前12名",
                dative: "前12名",
                color: "orange",
                columnWidth: 140,
            },
            top100: {
                title: "前100名",
                dative: "前100名",
                color: "red",
                columnWidth: 140,
            },
        },
    },
    financialAnalysisWb: {
        // единств, именительный падеж, кто? что?
        one: "财务分析",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "财务分析",
        // единств, дательный падеж, кому? чему?
        oneDative: "财务分析",
        // единств, винительный падеж, кого? что?
        oneAccusative: "财务分析",
        // множ, именительный падеж, кто? что?
        many: "财务分析",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "财务分析",
        // множ, дательный падеж, кому? чему?
        manyDative: "财务分析",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            sales_total: {
                title: "销售额 (RUB)",
                dative: "销售额 (RUB)",
                color: "lightgreen",
                type: "money",
                columnWidth: 140,
            },
            commission_total: {
                title: "佣金 (RUB)",
                dative: "佣金 (RUB)",
                color: "red",
                type: "money",
            },
            logistics_total: {
                title: "物流 (RUB)",
                dative: "物流 (RUB)",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            revenue_total: {
                title: "营收 (RUB)",
                dative: "营收 (RUB)",
                color: "orange",
                type: "money",
                columnWidth: 140,
            },
            commission_total_percent: {
                title: "最终佣金包括物流 %",
                dative: "最终佣金包括物流 %",
                color: "fiolet",
                type: "",
                columnWidth: 140,
            },
            cost_price_total: {
                title: "销售商品成本 (RUB)",
                dative: "销售商品成本 (RUB)",
                color: "blue",
                type: "money",
            },
            profit_total: {
                title: "利润 (RUB)",
                dative: "利润 (RUB)",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            marginality_total_percent: {
                title: "边缘性 (%)",
                dative: "边缘性 (%)",
                color: "green",
                type: "",
            },
            sales_total_count: {
                title: "销售额 (事物)",
                dative: "销售额 (事物)",
                type: "sales",
                color: "brown",
            },
            returns_total_count: {
                title: "退货 (事物)",
                dative: "退货 (事物)",
                type: "sales",
                color: "blue",
            },
            non_purchases_total_count: {
                title: "不回购 (事物)",
                dative: "不回购 (事物)",
                type: "sales",
                color: "pink",
            },
            orders_total_count: {
                title: "命令 (事物)",
                dative: "命令 (事物)",
                type: "sales",
                color: "blue",
            },
            commission_ratio_percent: {
                title: "佣金份额 (%)",
                dative: "佣金份额 (%)",
                color: "brown",
                type: "",
            },
            logistics_ratio_percent: {
                title: "物流分享 (%)",
                dative: "物流分享 (%)",
                type: "",
                color: "red",
            },
            average_check: {
                title: "平均支票 (RUB)",
                dative: "平均支票 (RUB)",
                type: "money",
                color: "lightgreen",
            },
            average_revenue: {
                title: "每个产品的平均收入 (RUB)",
                dative: "每个产品的平均收入 (RUB)",
                type: "money",
                color: "fiolet",
            },
            average_profit: {
                title: "每个产品的平均利润 (RUB)",
                dative: "每个产品的平均利润 (RUB)",
                type: "money",
                color: "red",
            },
        },
    },

    financialAnalysisOzon: {
        // единств, именительный падеж, кто? что?
        one: "财务分析",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "财务分析",
        // единств, дательный падеж, кому? чему?
        oneDative: "财务分析",
        // единств, винительный падеж, кого? что?
        oneAccusative: "财务分析",
        // множ, именительный падеж, кто? что?
        many: "财务分析",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "财务分析",
        // множ, дательный падеж, кому? чему?
        manyDative: "财务分析",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            sales_total: {
                title: "销售额 (RUB)",
                dative: "销售额 (RUB)",
                color: "lightgreen",
                type: "money",
                columnWidth: 140,
            },
            commission_total: {
                title: "佣金 (RUB)",
                dative: "佣金 (RUB)",
                color: "red",
                type: "money",
            },
            logistics_total: {
                title: "物流 (RUB)",
                dative: "物流 (RUB)",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            store_total_count: {
                title: "贮存 (RUB)",
                dative: "贮存 (RUB)",
                color: "green",
                type: "money",
                columnWidth: 140,
            },
            ads_total: {
                title: "广告 (RUB)",
                dative: "广告 (RUB)",
                color: "blue",
                type: "money",
                columnWidth: 140,
            },
            commission_total_percent: {
                title: "最终佣金 %",
                dative: "最终佣金 %",
                color: "lightgreen",
                type: "",
                columnWidth: 140,
            },
            revenue_total: {
                title: "营收 (RUB)",
                dative: "营收 (RUB)",
                color: "orange",
                type: "money",
                columnWidth: 140,
            },
            cost_price_total: {
                title: "销售商品成本 (RUB)",
                dative: "销售商品成本 (RUB)",
                color: "blue",
                type: "money",
            },
            profit_total: {
                title: "利润 (RUB)",
                dative: "利润 (RUB)",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            marginality_total_percent: {
                title: "边缘性 (%)",
                dative: "边缘性 (%)",
                color: "green",
                type: "",
            },
            sales_total_count: {
                title: "销售额 (事物)",
                dative: "销售额 (事物)",
                type: "sales",
                color: "brown",
            },
            returns_total_count: {
                title: "退货 (事物)",
                dative: "退货 (事物)",
                type: "sales",
                color: "blue",
            },
            non_purchases_total_count: {
                title: "不回购 (事物)",
                dative: "不回购 (事物)",
                type: "sales",
                color: "pink",
            },
            orders_total_count: {
                title: "命令 (事物)",
                dative: "命令 (事物)",
                type: "sales",
                color: "blue",
            },
            commission_ratio_percent: {
                title: "佣金份额 (%)",
                dative: "佣金份额 (%)",
                color: "brown",
                type: "",
            },
            logistics_ratio_percent: {
                title: "物流分享 (%)",
                dative: "物流分享 (%)",
                type: "",
                color: "red",
            },
            ads_ratio_percent: {
                title: "广告费用分摊 (%)",
                dative: "广告费用分摊 (%)",
                type: "",
                color: "brown",
            },
            other_ratio_percent: {
                title: "分摊其他费用 (%)",
                dative: "分摊其他费用 (%)",
                type: "",
                color: "pink",
            },
            average_check: {
                title: "平均支票 (RUB)",
                dative: "平均支票 (RUB)",
                type: "money",
                color: "lightgreen",
            },
            average_revenue: {
                title: "每个产品的平均收入 (RUB)",
                dative: "每个产品的平均收入 (RUB)",
                type: "money",
                color: "fiolet",
            },
            average_profit: {
                title: "每个产品的平均利润 (RUB)",
                dative: "每个产品的平均利润 (RUB)",
                type: "money",
                color: "red",
            },
            other_ratio_total_count: {
                title: "其他费用 (RUB)",
                dative: "其他费用 (RUB)",
                type: "money",
                color: "green",
            },
        },
    },

    product: {
        one: "产品",
        oneGenitive: "产品",
        oneDative: "产品",
        // единств, винительный падеж, кого? что?
        oneAccusative: "产品",
        many: "产品",
        manyGenitive: "产品",
        manyDative: "产品",
        textProp: "title",
        idProp: "p_id",
        gender: "male",
        metrics: {
            sold: {
                title: "销售额",
                dative: "销售额",
                color: "red",
                type: "sales",
            },
            revenue: {
                title: "营收",
                dative: "营收",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            price: {
                title: "价格",
                dative: "价格",
                color: "blue",
                type: "money",
            },
            price_old: {
                title: "旧价格",
                dative: "旧价格",
                type: "money",
                list: false,
                cell: false,
                table: true,
            },
            discount: {
                title: "折扣",
                dative: "折扣",
                type: "percent",
                list: false,
                cell: false,
                table: true,
            },
            price_spp: {
                title: "价格", //wb-no-spp
                dative: "价格",
                type: "money",
                color: "blue",
            },
            price_discount_spp: {
                title: "市场上老顾客的折扣",
                dative: "市场上老顾客的折扣",
                type: "percent",
                color: "pink",
            },

            remains: {
                title: "当前余额",
                dative: "当前余额",
                color: "lightgreen",
            },
            reviews: {
                title: "评论",
                dative: "评论",
                color: "fiolet",
            },
            rating: {
                title: "评分",
                dative: "评分",
                color: "lightyellow",
            },
            categories: {
                title: "类别数",
                dative: "类别数",
                color: "darkblue",
            },

            keywords: {
                title: "搜索查询",
                dative: "搜索查询",
                color: "blue",
                columnWidth: 140,
            },

            avg_sold: {
                title: "每日平均销售额",
                dative: "每日平均销售额",
                type: "sales",
                color: "gray",
                columnWidth: 140,
            },
            avg_revenue: {
                title: "平均每日收入",
                dative: "平均每日收入",
                type: "money",
                color: "gray",
                columnWidth: 140,
            },
            days: {
                title: "库存天数",
                dative: "库存天数",
                id: "days",
                type: "days",
                color: "pink",
                columnUnit: false,
            },
            losses: {
                title: "收入损失",
                dative: "收入损失",
                id: "losses",
                type: "money",
                color: "pink",
            },
        },
    },

    brand: {
        one: "品牌",
        oneGenitive: "品牌",
        oneDative: "品牌",
        // единств, винительный падеж, кого? что?
        oneAccusative: "品牌",
        many: "品牌",
        manyGenitive: "品牌",
        manyDative: "品牌",
        textProp: "brand",
        idProp: "b_id",
        gender: "male",
        metrics: {
            sold: {
                title: "销售额",
                dative: "销售额",
                color: "red",
                type: "sales",
            },
            revenue: {
                title: "营收",
                dative: "营收",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            products: {
                title: "产品",
                dative: "产品",
                color: "blue",
            },
            products_with_sales: {
                title: "有销售的产品",
                dative: "有销售的产品",
                percentOf: "products",
                color: "lightgreen",
                columnWidth: 140,
            },
            revenue_per_product: {
                title: "平均支票",
                dative: "平均支票",
                color: "orange",
                type: "money",
            },
            sellers: {
                title: "卖家",
                dative: "卖家",
                color: "green",
                columnWidth: 140,
            },
            sellers_with_sales: {
                title: "有销售的卖家",
                dative: "有销售的卖家",
                percentOf: "sellers",
                color: "brown",
                columnWidth: 140,
            },
            categories: {
                title: "类别数",
                dative: "类别数",
                color: "darkblue",
            },
            avg_rating: {
                title: "货物数量",
                dative: "货物数量",
                color: "lightyellow",
            },
            reviews: {
                title: "评论",
                dative: "评论",
                color: "fiolet",
            },
            reviews_per_product: {
                title: "产品评论",
                dative: "产品评论",
                color: "pink",
            },
            avg_sold: {
                title: "每日平均销售额",
                dative: "每日平均销售额",
                type: "sales",
                color: "gray",
                columnWidth: 140,
            },
            avg_revenue: {
                title: "平均每日收入",
                dative: "平均每日收入",
                type: "money",
                color: "gray",
                columnWidth: 140,
            },
        },
    },
    seller: {
        one: "推销员",
        oneGenitive: "推销员",
        oneDative: "推销员",
        // единств, винительный падеж, кого? что?
        oneAccusative: "推销员",
        many: "推销员",
        manyGenitive: "推销员",
        manyDative: "推销员",
        // множ, винительный падеж, кого? что?
        manyAccusative: "推销员",
        textProp: "seller",
        idProp: "s_id",
        gender: "male",
        metrics: {
            sold: {
                title: "销售额",
                dative: "销售额",
                color: "red",
                type: "sales",
            },
            revenue: {
                title: "营收",
                dative: "营收",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            products: {
                title: "产品",
                dative: "产品",
                color: "blue",
            },
            products_with_sales: {
                title: "有销售的产品",
                dative: "有销售的产品",
                percentOf: "products",
                color: "lightgreen",
                columnWidth: 140,
            },
            revenue_per_product: {
                title: "平均支票",
                dative: "平均支票",
                color: "orange",
                type: "money",
            },
            brands: {
                title: "品牌",
                dative: "品牌",
                color: "green",
            },
            brands_with_sales: {
                title: "有销量的品牌",
                dative: "有销量的品牌",
                percentOf: "brands",
                color: "brown",
                columnWidth: 140,
            },
            categories: {
                title: "类别数",
                dative: "类别数",
                color: "darkblue",
            },
            avg_rating: {
                title: "货物数量",
                dative: "货物数量",
                color: "lightyellow",
            },
            reviews: {
                title: "评论",
                dative: "评论",
                color: "fiolet",
            },
            reviews_per_product: {
                title: "产品评论",
                dative: "产品评论",
                color: "pink",
            },
            avg_sold: {
                title: "每日平均销售额",
                dative: "每日平均销售额",
                type: "sales",
                color: "gray",
                columnWidth: 140,
            },
            avg_revenue: {
                title: "平均每日收入",
                dative: "平均每日收入",
                type: "money",
                color: "gray",
                columnWidth: 140,
            },
        },
    },
    reviewManagementWb: {
        // единств, именительный падеж, кто? что?
        one: "店铺",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "店铺",
        // единств, дательный падеж, кому? чему?
        oneDative: "店铺",
        // единств, винительный падеж, кого? что?
        oneAccusative: "店铺",
        // множ, именительный падеж, кто? что?
        many: "店铺",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "店铺",
        // множ, дательный падеж, кому? чему?
        manyDative: "店铺",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            avg_valuation: {
                title: "平均分",
                dative: "平均分",
                color: "red",
                // type: "sales",
                columnWidth: 140,
            },
            count_feedbacks: {
                title: "总评论数",
                dative: "总评论数",
                color: "yellow",
                type: "sales",
                columnWidth: 140,
            },
            count_per_day: {
                title: "每日评论",
                dative: "每日评论",
                color: "blue",
                type: "sales",
                columnWidth: 140,
            },
            count_unanswered: {
                title: "评论不回复",
                dative: "评论无回复",
                color: "lightgreen",
                type: "sales",
                columnWidth: 140,
            },
            count_need_approval: {
                title: "需要适度",
                dative: "需要适度",
                color: "brown",
                type: "sales",
                columnWidth: 140,
            },
            count_today_unanswered: {
                title: "今天没有回复",
                dative: "今天没有回复",
                color: "orange",
                type: "sales",
                columnWidth: 140,
            },
            count_scripts: {
                title: "活动脚本",
                dative: "活动脚本",
                color: "fiolet",
                type: "sales",
                columnWidth: 140,
            },
            count_answers: {
                title: "通过脚本回答",
                dative: "通过脚本回答",
                color: "pink",
                type: "sales",
                columnWidth: 140,
            },
        },
    },
};
export const MARKETPLACES = {
    wb: "Wildberries",
    ozon: "OZON",
};

export function getEntitiesList(
    text = "many", // manyGenitive, manyDative
    textCase = "capitalize" // lower, upper
) {
    const result = {
        category: ENTITIES.category,
        product: ENTITIES.product,
        brand: ENTITIES.brand,
        seller: ENTITIES.seller,
    };

    const textCaseFns = {
        capitalize: (v) => v.charAt(0).toUpperCase() + v.slice(1),
        lower: (v) => v.toLowerCase(),
        upper: (v) => v.toUpperCase(),
    };
    Object.keys(result).forEach((key) => {
        if (textCase === null) {
            result[key] = result[key][text];
            return;
        }
        result[key] = textCaseFns[textCase](result[key][text]);
    });
    return result;
}

export function getMonitorListM() {
    ////text = "many", // manyGenitive, manyDative
    ////textCase = "capitalize" // lower, upper
    const result = {
        productPrice: "产品 : 价格",
        productDiscount: "产品 : 折扣",
        productReview: "产品 : 评论数量",
        productMaxQuantity: "产品 : 剩菜",
        productPhotoCount: "产品 : 图片数量",
        productCategory: "产品 : 类别数",
        productKeyword: "产品 : 关键词数量",
        productNewName: "产品名称",

        brandNewProduct: "品牌 : 新产品",
        brandNewSeller: "品牌 : 新卖家",

        sellerNewProduct: "卖家 : 新产品",
        sellerNewBrand: "卖家 : 新品牌",

        categoryNewProduct: "类别 : 新产品",
        categoryNewBrand: "类别 : 新品牌",
        categoryNewSeller: "类别 : 新卖家",
    };

    /* @V:
            // const textCaseFns = {
            //     capitalize: (v) => v.charAt(0).toUpperCase() + v.slice(1),
            //     lower: (v) => v.toLowerCase(),
            //     upper: (v) => v.toUpperCase(),
            // };
            // Object.keys(result).forEach((key) => {
            //     if (textCase === null) {
            //         result[key] = result[key][text];
            //         return;
            //     }
            //     result[key] = textCaseFns[textCase](result[key][text]);
            // });
            */

    return result;
}

function _convertToArray(obj) {
    return Object.keys(obj).map((key) => {
        return {
            title: obj[key],
            id: key,
        };
    });
}

export function getEntitiesListArray(text = "many", textCase = "capitalize") {
    const obj = getEntitiesList(text, textCase);
    return _convertToArray(obj);
}

export function getMonitorListMArray() {
    //text = "many", textCase = "capitalize") {
    const obj = getMonitorListM(); //text, textCase);
    return _convertToArray(obj);
}

export function getMarketplacesList() {
    return MARKETPLACES;
}

export function getMarketplacesListArray() {
    const obj = getMarketplacesList();
    return _convertToArray(obj);
}

// [1, 2, 5] для плюрализации
export function getEntityPluralVariants(entity, caseOne) {
    const variants = ENTITIES[entity];
    return [variants[caseOne], variants.oneGenitive, variants.manyGenitive];
}

// Прюрализация сущности
export function pluralEntity(
    entity,
    number,
    format = "{v} {entity}",
    caseOne = "one"
) {
    const pluralFormats = getEntityPluralVariants(entity, caseOne).map(
        (variant) => format.replace("{entity}", variant)
    );
    return plural(number, pluralFormats);
}

export function gender(entity, [male, female, neuter]) {
    const gender = ENTITIES[entity].gender;
    if (gender === "male") {
        return male;
    }
    if (gender === "female") {
        return female;
    }
    if (gender === "neuter") {
        return neuter;
    }
}

export function getEntityCellsConfig(entity) {
    const config = ENTITIES[entity];

    const result = {};
    Object.keys(config.metrics).forEach((key) => {
        const metric = config.metrics[key];
        if (metric.cell === false) {
            return;
        }
        const units = {
            money: "RUB",
            sales: "事物",
            days: "天",
            default: null,
        };
        result[key] = {
            id: key,
            title: metric.title,
            color: metric.color,
            percentOf: metric.percentOf,
            unit: units[metric.type] || units.default,
        };
    });
    return result;
}

export function getEntityTableColumns(
    entity,
    startPosition = 1,
    preferred = null
) {
    const config = ENTITIES[entity];

    const result = [];
    let currentPosition = startPosition;

    const addColumn = (key) => {
        const metric = config.metrics[key];
        if (metric.table === false) {
            return;
        }
        const defaults = {
            money: {
                type: "money",
            },
            sales: {
                title:
                    `${metric.title}` +
                    (metric.columnUnit === false ? "" : " (事物)"),
            },
            days: {
                title:
                    `${metric.title}` +
                    (metric.columnUnit === false ? "" : " (天)"),
            },
            percent: {
                type: "percent",
            },
            default: {},
        };
        result.push({
            name: key,
            title: metric.title,
            show: true,
            position: currentPosition++,
            width: metric.columnWidth || 100,
            filter: "numberRange",
            type: "number",
            ...(defaults[metric.type] || defaults.default),
        });
    };

    let preferredArray = [];
    if (preferred) {
        preferredArray = Array.isArray(preferred) ? preferred : [preferred];
    }
    preferredArray.forEach(addColumn);

    Object.keys(config.metrics).forEach((key) => {
        if (preferredArray.includes(key)) {
            return;
        }
        addColumn(key);
    });
    return result;
}

export function getEntityMetricsList(entity, text = "title", mp=null) {
    const config = ENTITIES[entity];

    //console.log('1308: mp=', mp)

    const result = {};
    Object.keys(config.metrics).forEach((key) => {
        let metric = config.metrics[key];
        if (metric.list === false) {
            return;
        }

        //&:2024-02-12
        if( mp!==null
            && (metric.mp==="wb" || metric.mp==="ozon")
            && metric.mp!==mp
        ){
            return;
        }
        //подшаманим названия полей цен для меню
        if( mp==="wb" ){
            if( key==='price') metric[text] = "Цена с WB кошельком"
            ////else if (key==='price_discount_spp') metric[text] = "СПП"
            else if (key==='price_spp') metric[text] = "Цена"
            else if (key==='discount_wb') metric[text] = "Скидка"
        }else{
            if( key==='price') metric[text] = "Цена"
        }

        result[key] = metric[text];
    });
    return result;
}

export function getEntityMetricsListArray(entity, mp=null) {
    //console.log('1331: mp=', mp)

    let text = "title"
    const obj = getEntityMetricsList(entity, text, mp);
    return _convertToArray(obj);
}
