//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppSidebar from "@/components/AppSidebar.vue";
import AppHeader from "@/components/AppHeader.vue";
import ModalWelcome from "@/components/modals/ModalWelcome";
//import SelfDemo from "@/components/Alert/SelfDemo.vue"; ##SELF-DEMO-OFF##
import NewsBar from "@/components/Alert/NewsBar.vue";
export default {
  data() {
    let waitForMountedResolver;
    const waitForMountedPromise = new Promise(resolve => {
      waitForMountedResolver = resolve;
    });
    return {
      waitForMountedPromise,
      waitForMountedResolver,
      showNewsBar: false
      //showSelfDemo: true,   ##SELF-DEMO-OFF##
    };
  },

  components: {
    AppSidebar,
    AppHeader,
    ModalWelcome,
    //SelfDemo, ##SELF-DEMO-OFF##
    NewsBar
  },
  mounted() {
    document.documentElement.classList.add("min-width");
    this.waitForMountedResolver();
  },
  watch: {
    $route: {
      async handler(route) {
        if (route.params.showWelcomeModal) {
          await this.waitForMountedPromise;
          this.$refs.ModalWelcome.open();
        }
      },
      immediate: true
    }
  },
  destroyed() {
    document.documentElement.classList.remove("min-width");
  },
  computed: {
    user() {
      let user_w_tarif = null == this.$store.state.billing.currentSubscriptions || this.$store.state.billing.currentSubscriptions.length == 0 ? 0 : 1;

      //console.log('user_w_tarif=',user_w_tarif)

      //user_w_tarif=0; //!dbg: для показа транспоранта в любом случае

      return user_w_tarif;
    }
  }
};