import Vue from "vue";
import { translate } from "@/utils/translate";

export default {
    namespaced: true,
    actions: {
        async getCategory(context, { c_id, mp, date, date2, fbs }) {
            let { data } = await Vue.api.get("/getCategory", {
                params: {
                    mp,
                    date,
                    date2,
                    c_id,
                    fbs,
                },
            });
            data.c_name = translate(
                data.c_name,
                context.rootState.chineseDictionary
            );
            return data;
        },
        async getCategoryList(context, { c_id, mp }) {
            let { data } = await Vue.api.get("/getCategoryList", {
                params: {
                    mp,
                    c_id,
                },
            });
            data = data.map((item) => {
                return {
                    ...item,
                    c_name: translate(
                        item.c_name,
                        context.rootState.chineseDictionary
                    ),
                };
            });
            return data;
        },
        async getCategoryByName(context, { query, mp }) {
            const req = Object.keys(context.rootState.chineseDictionary).find(
                (key) => {
                    return (
                        context.rootState.chineseDictionary[key].trim() == query
                    );
                }
            );
            let { data } = await Vue.api.get("/getCategoryByName", {
                params: {
                    query: req || "",
                    mp,
                },
            });
            data.forEach((item) => {
                let categories = item.c_name_path.split("/");
                categories = categories.map((category) => {
                    return translate(
                        category.trim(),
                        context.rootState.chineseDictionary
                    );
                });
                item.c_name_path = categories.join(" / ");
                item.c_name = translate(
                    item.c_name,
                    context.rootState.chineseDictionary
                );
            });
            return data;
        },
        async categoryOverviewAll(context, { mp, c_id, date, date2, fbs }) {
            const { data } = await Vue.api.get("/categoryOverviewAll", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    fbs,
                },
            });
            return data;
        },
        // @DEPRECATED
        async categoryOverview(context, { mp, c_id, date, date2, type }) {
            const { data } = await Vue.api.get("/categoryOverview", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    type,
                },
            });
            return data;
        },
        // @DEPRECATED
        async categoryOverviewDynamic(
            context,
            { mp, c_id, date, date2, type }
        ) {
            const { data } = await Vue.api.get("/categoryOverviewDynamic", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    type,
                },
            });
            return data;
        },
        async getCategoryProducts(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategoryProducts", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            data.items.forEach((item) => {
                let words = item.title.split(" ");
                words = words.map((word) => {
                    return translate(
                        word.trim(),
                        context.rootState.chineseDictionary
                    );
                });
                item.title = words.join(" ");
            });
            return data;
        },
        async getCategoryBrands(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategoryBrands", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getCategorySellers(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategorySellers", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getCategorySubcategories(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategorySubcategories", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        //kwd
        async getCategoryKeywords(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            let { data } = await Vue.api.get("/getCategoryKeywords", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            data.items.forEach((item) => {
                let words = item.keyword.split(" ");
                words = words.map((word) => {
                    return translate(
                        word.trim(),
                        context.rootState.chineseDictionary
                    );
                });
                item.keyword = words.join(" ");
            });
            return data;
        },
        async getCategorySegments(context, queryParams) {
            const { mp, c_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getCategorySegments", {
                params: {
                    mp,
                    c_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
    },
};
