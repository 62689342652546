//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { SlideYUpTransition } from "vue2-transitions";

export default {
  data() {
    return {
      popup: false
    };
  },
  components: {
    // SlideYUpTransition,
  }
};