import Vue from "vue";
export default {
    namespaced: true,
    state: {
        user: null,
        outOfLimit: null,
        _userLoadingPromise: null,
        tarif: null,
        settings: {},
    },
    mutations: {
        SET_USER(state, data) {
            state.user = data;
        },
        SET_USER_LOADING_PROMISE(state, data) {
            state._userLoadingPromise = data;
        },
        SET_OUT_OF_LIMIT(state, data) {
            state.outOfLimit = data;
        },
        SET_SETTINGS(state, data) {
            state.settings = data;
        },
        SET_SETTING(state, { name, value }) {
            state.settings[name] = value;
        },
    },
    actions: {
        async checkEmail(ctx, { user_email_address } = {}) {
            try {
                await Vue.api.get("/user/checkEmail", {
                    params: { user_email_address },
                });
                return true;
            } catch (e) {
                if (e.response?.status === 409) {
                    return false;
                }
                throw e;
            }
        },
        //&:
        async checkPhone(ctx, { user_phone, user_password } = {}) {
            try {
                const rc = await Vue.api.get("/user/checkPhone", {
                    params: { user_phone, user_password },
                });
                //return true;
                return rc;
            } catch (e) {
                if( e.response?.status===409 || e.response?.status===418 ){
                    throw e;
                }
                return false;
            }
        },

        /**| //}}skip-phone-sms
        async checkUserPhoneBySMS(ctx, { user_phone, user_password } = {}) {
            try {
                let res = await Vue.api.get("/user/checkUserPhoneBySMS", {
                    params: { user_phone, user_password },
                });

                console.log('checkUserPhoneBySMS: res=',res)

                return res;
            } catch (e) {
                if (e.response?.status === 409) {
                    return false;
                }
                throw e;
            }
        },
        async checkSMSCode(ctx, { id, code } = {}) {

            console.log('checkSMSCode: id=',id, ' code=',code)

            try {
                const res = await Vue.api.get("/user/checkSMSCode", {
                    params: { id, code },
                });
                return res;
            } catch (e) {
                if (e.response?.status === 409) {
                    return false;
                }
                throw e;
            }
        },

        async saveBadTrySMS(ctx, { email, phone } = {}) {

            console.log('saveBadTrySMS: email=', email, ' phone=', phone)

            const rc = await Vue.api.get("/user/saveBadTrySMS", {
                params: { email, phone },
            });

            return rc
        },
        /**/ //}}skip-phone-sms

        /* //{{skip-phone-sms
        // async checkTrySMS(
        //     ////ctx, { }
        // ){
        //     try {
        //         const res = await Vue.api.get("/user/checkTrySMS", {
        //             params: { },
        //         });

        //         console.log('checkTrySMS: res=', res)

        //         return res;
        //     } catch (e) {

        //         console.log('checkTrySMS: e=', e)

        //         if (e.response?.status === 409) {
        //             throw e;
        //             //return false;
        //         }
        //         throw e;
        //     }
        // },
        /**/ //}}skip-phone-sms

        async fetchGetUser({ commit }) {
            try {
                if (window.PRERENDER) {
                    return false;
                }
                const {
                    data: {
                        user,
                        applyingSubscriptions,
                        currentSubscriptions,
                        limitsOwner,
                        settings,
                    },
                } = await Vue.api.get("/user/getUser");
                commit("SET_USER", user);
                commit("SET_SETTINGS", settings);
                commit(
                    "billing/SET_APPLYING_SUBSCRIPTIONS",
                    applyingSubscriptions,
                    {
                        root: true,
                    }
                );
                commit(
                    "billing/SET_CURRENT_SUBSCRIPTIONS",
                    currentSubscriptions,
                    {
                        root: true,
                    }
                );
                commit("billing/SET_LIMITS_OWNER", limitsOwner, {
                    root: true,
                });

                return user;
            } catch (e) {
                if (e.response?.status === 401) {
                    commit("SET_USER", false);
                    if (e.response?.data?.outOfLimit) {
                        commit("SET_OUT_OF_LIMIT", e.response.data.outOfLimit);
                    }
                    return false;
                }
                throw e;
            }
        },
        async getUser({ state, commit, dispatch }, { force = false } = {}) {
            if (!force) {
                if (state.user !== null) {
                    return state.user;
                }
                if (state._userLoadingPromise) {
                    return state._userLoadingPromise;
                }
            }
            const fetchPromise = dispatch("fetchGetUser");
            commit("SET_USER_LOADING_PROMISE", fetchPromise);
            const result = await fetchPromise;
            commit("SET_USER_LOADING_PROMISE", null);
            return result;
        },
        async signUp(
            { commit },
            { user_email_address, user_password, user_phone
                ////, smsId, smsUserCode    //skip-phone-sms
            }
        ) {
            const {
                data: {
                    user,
                    applyingSubscriptions,
                    currentSubscriptions,
                    limitsOwner,
                    settings,
                },
            } = await Vue.api.post("/user/signUp", {
                user_email_address,
                user_password,
                user_phone,
                ////smsId, smsUserCode, //skip-phone-sms
            });

            commit("SET_USER", user);
            commit("SET_SETTINGS", settings);
            commit(
                "billing/SET_APPLYING_SUBSCRIPTIONS",
                applyingSubscriptions,
                {
                    root: true,
                }
            );
            commit("billing/SET_CURRENT_SUBSCRIPTIONS", currentSubscriptions, {
                root: true,
            });
            commit("billing/SET_LIMITS_OWNER", limitsOwner, {
                root: true,
            });
            return user;
        },
        async restore(ctx, { user_email_address }) {
            await Vue.api.post("/user/restore", {
                user_email_address,
            });
        },
        async signIn({ commit }, { user_email_address, user_password }) {
            const {
                data: {
                    user,
                    applyingSubscriptions,
                    currentSubscriptions,
                    limitsOwner,
                    settings,
                },
            } = await Vue.api.post("/user/signIn", {
                user_email_address,
                user_password,
            });
            commit("SET_USER", user);
            commit("SET_SETTINGS", settings);
            commit(
                "billing/SET_APPLYING_SUBSCRIPTIONS",
                applyingSubscriptions,
                {
                    root: true,
                }
            );
            commit("billing/SET_CURRENT_SUBSCRIPTIONS", currentSubscriptions, {
                root: true,
            });
            commit("billing/SET_LIMITS_OWNER", limitsOwner, {
                root: true,
            });
            return user;
        },
        async signOut({ commit }) {
            try {
                await Vue.api.post("/user/signOut");
            } catch (e) {
                if (e.response?.status === 401) {
                    commit("SET_USER", false);
                    return;
                }
                throw e;
            }
        },
        async changePassword(ctx, { user_password_current, user_password }) {
            await Vue.api.post("/user/changePassword", {
                user_password_current,
                user_password,
            });
        },
        async pageVisit(ctx, { url }) {
            await Vue.api.post("/user/pageVisit", {
                url,
            });
        },
        async getHistory(ctx, queryParams) {
            const { data } = await Vue.api.get("/user/getHistory", {
                params: queryParams,
            });
            return data;
        },
        async getChilds(ctx, queryParams) {
            const { data } = await Vue.api.get("/user/getChilds", {
                params: queryParams,
            });
            return data;
        },
        async inviteChild(ctx, { user_email_address }) {
            const { data: user } = await Vue.api.post("/user/inviteChild", {
                user_email_address,
            });
            return user;
        },
        async disableChild(ctx, { id }) {
            const { data: link } = await Vue.api.put("/user/disableChild", {
                id,
            });
            return link;
        },
        async enableChild(ctx, { id }) {
            const { data: link } = await Vue.api.put("/user/enableChild", {
                id,
            });
            return link;
        },
        async setSetting(ctx, { name, value }) {
            await Vue.api.put("/user/setSetting", {
                name,
                value,
            });

            ctx.commit("SET_SETTINGS", { name, value });
        },
        async setSelfDemoPlan(ctx, { param } = {}) {
            try {
                await Vue.api.put("/billing/setSelfDemoPlan", {
                    params: { param },
                });
                return true;
            } catch (e) {
                return false;
            }
        },
    },
};
