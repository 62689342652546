import Vue from "vue";
export default {
    namespaced: true,
    state: {
        // тарифный план без проверки на экспирацию
        // current / parent
        currentMode: null,
        currentSubscriptions: null,

        // активные подписки
        // current / parent
        mode: null,
        applyingSubscriptions: null,
        limitsOwner: null,
    },
    mutations: {
        SET_CURRENT_MODE(state, data) {
            state.currentMode = data;
        },
        SET_CURRENT_SUBSCRIPTIONS(state, data) {
            state.currentSubscriptions = data;
        },
        SET_MODE(state, data) {
            state.mode = data;
        },
        SET_APPLYING_SUBSCRIPTIONS(state, data) {
            state.applyingSubscriptions = data;
        },
        SET_LIMITS_OWNER(state, data) {
            state.limitsOwner = data;
        },
    },
    actions: {
        async getPlans(context, params) {
            const { data } = await Vue.api.get("/billing/plans", {
                params,
            });
            return data;
        },
        async getState(context, params) {
            const { data } = await Vue.api.get("/billing/state", {
                params,
            });
            context.commit("SET_CURRENT_MODE", data.currentMode);
            context.commit(
                "SET_CURRENT_SUBSCRIPTIONS",
                data.currentSubscriptions
            );
            context.commit("SET_MODE", data.mode);
            context.commit("SET_APPLYING_SUBSCRIPTIONS", data.subscriptions);
            return data;
        },
        async getLimits() {
            const { data } = await Vue.api.get("/billing/limits");
            return data;
        },
        async calculateOrder(context, body) {
            const { data } = await Vue.api.post(
                "/billing/calculateOrder",
                body
            );
            return data;
        },
        async changeSubscription(context, body) {
            const { data } = await Vue.api.post(
                "/billing/changeSubscription",
                body
            );
            return data;
        },
        async createOrder(context, body) {
            const { data } = await Vue.api.post("/billing/createOrder", body);
            return data;
        },
        async getOrder(context, params) {
            const { data } = await Vue.api.get("/billing/order", {
                params,
            });
            return data;
        },
        async payManualOrder(context, body) {
            const { data } = await Vue.api.post(
                "/billing/payManualOrder",
                body
            );
            return data;
        },
        async getManualOrder(context, body) {
            const { data } = await Vue.api.post(
                "/billing/getManualOrder",
                body
            );
            return data;
        },
        async getHistory(ctx, queryParams) {
            const { data } = await Vue.api.get("/billing/getHistory", {
                params: queryParams,
            });
            return data;
        },
        async getCurrentOrganization(context, params) {
            const { data } = await Vue.api.get(
                "/billing/getCurrentOrganization",
                {
                    params,
                }
            );
            return data;
        },
        async updateCurrentOrganization(context, body) {
            const { data } = await Vue.api.put(
                "/billing/updateCurrentOrganization",
                body
            );
            return data;
        },

        //&:china
        async trySetChinaPlan(context, body) {
            const { data } = await Vue.api.put(
                "/billing/trySetChinaPlan",
                body
            );
            return data;
        },
        async isChinaCode(context, body) {
            const { data } = await Vue.api.put(
                "/billing/isChinaCode",
                body
            );
            return data;
        },

        //&: self-demo
        async setSelfDemoPlan(ctx, body) {
            const { data } = await Vue.api.put(
                "/billing/setSelfDemoPlan",
                body
            );
            return data;
        },
        /*
        async payChinaOrder(context, body) {
            const { data } = await Vue.api.post(
                "/billing/payChinaOrder",
                body
            );
            return data;
        },*/

    },
};
