import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { debounce } from "throttle-debounce";

Vue.use(VueRouter);

const redirectToDefaultTab = (tab) => (to, from, next) => {
    if (!to.params.tab) {
        next({
            ...to,
            params: {
                ...to.params,
                tab,
            },
        });
        return;
    }
    next();
};

const routes = [
    {
        path: "/",
        name: "Index",
        component: () => import("../views/public/Index/Index.vue"),
        meta: {
            layout: "empty-layout",
            unauthorizedOnly: true,
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: () => import("../views/public/Contact.vue"),
        meta: {
            layout: "empty-layout",
        },
    },
    {
        path: "/features",
        name: "Features",
        component: () => import("../views/public/Features/Features.vue"),
        meta: {
            layout: "empty-layout",
        },
    },
    {
        path: "/pricing",
        name: "Plans",
        component: () => import("../views/public/Plans/Plans.vue"),
        meta: {
            layout: "empty-layout",
        },
    },
    {
        path: "/partner",
        name: "Partner",
        component: () => import("../views/public/Partner/Partner.vue"),
        meta: {
            layout: "empty-layout",
        },
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: () => import("../views/public/Privacy.vue"),
        meta: {
            layout: "empty-layout",
        },
    },
    {
        path: "/agreement",
        name: "Agreement",
        component: () => import("../views/public/Agreement.vue"),
        meta: {
            layout: "empty-layout",
        },
    },

    //&:
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
    },

    {
        path: "/login",
        name: "Login",
        component: () => import("../views/public/Login/Login.vue"),
        meta: {
            layout: "empty-layout",
            action: "login",
            unauthorizedOnly: true,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/public/Login/Login.vue"),
        meta: {
            layout: "empty-layout",
            action: "register",
            unauthorizedOnly: true,
        },
    },
    {
        path: "/restorepassword",
        name: "RestorePassword",
        component: () => import("../views/public/Login/Login.vue"),
        meta: {
            layout: "empty-layout",
            action: "restorepassword",
            unauthorizedOnly: true,
        },
    },
    {
        path: "/:mp(wb|ozon)/category",
        name: "CategoryHome",
        component: () => import("../views/CategoryHome.vue"),
    },
    {
        path: "/:mp(wb|ozon)/category/:c_id/info/:tab?",
        name: "CategoryInfo",
        beforeEnter: redirectToDefaultTab("products"),
        component: () => import("../views/CategoryInfo.vue"),
    },
    {
        path: "/:mp(wb|ozon)/brand",
        name: "BrandHome",
        component: () => import("../views/BrandHome.vue"),
    },
    {
        path: "/:mp(wb|ozon)/brand/:b_id/info/:tab?",
        name: "BrandInfo",
        beforeEnter: redirectToDefaultTab("products"),
        component: () => import("../views/BrandInfo.vue"),
    },
    {
        path: "/:mp(wb|ozon)/seller",
        name: "SellerHome",
        component: () => import("../views/SellerHome.vue"),
    },
    {
        path: "/:mp(wb|ozon)/seller/:s_id/info/:tab?",
        name: "SellerInfo",
        beforeEnter: redirectToDefaultTab("products"),
        component: () => import("../views/SellerInfo.vue"),
    },
    {
        path: "/:mp(wb|ozon)/product",
        name: "ProductHome",
        component: () => import("../views/ProductHome.vue"),
    },
    {
        path: "/:mp(wb|ozon)/product/:p_id/info/:tab?",
        name: "ProductInfo",
        beforeEnter: redirectToDefaultTab("days"),
        component: () => import("../views/ProductInfo.vue"),
    },
    {
        path: "/lists",
        name: "Lists",
        component: () => import("../views/Lists.vue"),
        meta: {
            // authorizedOnly: true
        },
    },

    {
        path: "/listMs",
        name: "ListMs",
        component: () => import("../views/ListMs.vue"),
        meta: {
            // authorizedOnly: true
        },
    },

    {
        path: "/position-monitoring",
        name: "PositionMonitoring",
        component: () => import("../views/PositionMonitoring.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/queries-selection/:mp(wb|ozon)?",
        name: "QueriesSelection",
        component: () => import("../views/QueriesSelection.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/financial-analysis",
        name: "FinancialAnalysis",
        component: () => import("../views/FinancialAnalysis.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/position-monitoring/projectInfo/:project_id",
        name: "ProjectInfo",
        component: () => import("../views/Project.vue"),
    },
    {
        path: "/connections",
        name: "Connections",
        component: () => import("../views/Connections.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/highlights/:mp(wb|ozon)?",
        name: "Highlights",
        beforeEnter: redirectToDefaultTab("products"),
        component: () => import("../views/Highlights.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/orders/:mp(wb|ozon)?",
        name: "Orders",
        component: () => import("../views/Orders.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/sales/:mp(wb|ozon)?",
        name: "Sales",
        component: () => import("../views/Sales.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/sales-geography/:mp(wb|ozon)?",
        name: "SalesGeography",
        component: () => import("../views/SalesGeography.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/storage/:mp(wb|ozon)?",
        name: "Storage",
        component: () => import("../views/Storage.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/products/:mp(wb|ozon)?",
        name: "Products",
        component: () => import("../views/Products.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/returns/:mp(wb|ozon)?",
        name: "Returns",
        component: () => import("../views/Returns.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/abcAnalysis/:mp(wb|ozon)?",
        name: "ABCAnalysis",
        component: () => import("../views/ABC.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/delivery-calculation/:mp(wb|ozon)?",
        name: "DeliveryCalculation",
        component: () => import("../views/DeliveryCalculation.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/compare/:mp(wb|ozon)?",
        name: "Compare",
        component: () => import("../views/Compare.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/list/:id",
        name: "ListView",
        component: () => import("../views/ListView.vue"),
        meta: {
            authorizedOnly: true,
        },
    },
    {
        path: "/listM/:id",
        name: "ListMView",
        component: () => import("../views/ListMView.vue"),
        meta: {
            authorizedOnly: true,
        },
    },

    /*
    {
        path: "/listM/:id/info/:tab?",
        name: "ListMInfo",
            ////beforeEnter: redirectToDefaultTab("days"),
        component: () => import("../views/ListMInfo.vue"),
    },
    */

    {
        path: "/:mp(wb|ozon)/ratings",
        name: "Ratings",
        redirect: (to) => {
            return {
                name: "Rating",
                params: {
                    mp: to.params.mp,
                    type: "categories",
                },
            };
        },
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/:mp(wb|ozon)/ratings/:type(categories|products|new|brands|sellers|ups|downs|constructor)",
        name: "Rating",
        component: () => import("../views/Ratings.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/profile/Index.vue"),
        meta: {
            authorizedOnly: true,
        },
        children: [
            {
                path: "settings",
                name: "ProfileSettings",
                component: () => import("../views/profile/Settings.vue"),
                meta: {
                    authorizedOnly: true,
                },
            },
            {
                path: "plan",
                name: "ProfilePlan",
                component: () => import("../views/profile/Plan.vue"),
                meta: {
                    authorizedOnly: true,
                },
            },
            {
                path: "history",
                name: "ProfileHistory",
                component: () => import("../views/profile/History.vue"),
                meta: {
                    authorizedOnly: true,
                },
            },
            {
                path: "partner",
                name: "ProfilePartner",
                component: () => import("../views/profile/Partner.vue"),
                meta: {
                    authorizedOnly: true,
                },
            },
        ],
        redirect: () => {
            return {
                name: "ProfileSettings",
            };
        },
    },
    {
        path: "/registered",
        name: "Registered",
        component: () => import("../views/public/Registered.vue"),
        meta: {
            layout: "empty-layout",
            authorizedOnly: true,
        },
    },
    {
        path: "/pay",
        name: "Pay",
        component: () => import("../views/public/Pay.vue"),
        meta: {
            layout: "empty-layout",
        },
    },
    {
        path: "/payment/success",
        name: "PaymentSuccess",
        component: () => import("../views/public/Payment/Success.vue"),
        meta: {
            layout: "empty-layout",
        },
    },
    {
        path: "/payment/fail",
        name: "PaymentFail",
        component: () => import("../views/public/Payment/Fail.vue"),
        meta: {
            layout: "empty-layout",
        },
    },
    //&:china
    {
        path: "/promo",
        name: "Promo",
        component: () => import("../views/public/Promo/Promo.vue"),
        meta: {},
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/review-management-connections",
        name: "ReviewManagementConnections",
        component: () => import("../views/ReviewManagementConnections.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/review-management-highlights",
        name: "ReviewManagementHighlights",
        component: () => import("../views/ReviewManagementHighlights.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/review-management-scripts",
        name: "ReviewManagementScripts",
        component: () => import("../views/ReviewManagementScripts.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/review-management-script/:id?",
        name: "ReviewManagementScript",
        component: () => import("../views/ReviewManagementScript.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/review-management-categories",
        name: "ReviewManagementCategories",
        component: () => import("../views/ReviewManagementCategories.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/review-management-reviews-new",
        name: "ReviewManagementReviewsNew",
        component: () => import("../views/ReviewManagementReviewsNew.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/review-management-reviews-archive",
        name: "ReviewManagementReviewsArchive",
        component: () => import("../views/ReviewManagementReviewsArchive.vue"),
        meta: {
            // authorizedOnly: true
        },
    },
    {
        path: "/plugin-welcome",
        name: "PluginWelcome",
        beforeEnter: () => {
            window.location.pathname = "/plugin-welcome.html";
        },
        meta: {
            // authorizedOnly: true
        },
    },
];

const router = new VueRouter({
    routes: routes.map((route) => {
        return {
            ...route,
            component: async () => {
                const component = await route.component();
                return {
                    extends: component.default,
                    mounted() {
                        if (window.PRERENDER) {
                            // Hydration temporarly disabled cause when it fails with NODE_ENV=production, it crashes the app entirely
                            // data-attributes to perform proper hydration
                            // @see https://github.com/chrisvfritz/prerender-spa-plugin#vuejs-notes
                            // this.$root.$el.setAttribute('data-server-rendered', 'true')
                            // same for vue-meta plugin
                            // @see https://vue-meta.nuxtjs.org/guide/ssr.html#inject-metadata-into-page-stream
                            // document.documentElement.setAttribute('data-vue-meta-server-rendered', 'true')
                        }
                        document.dispatchEvent(new Event("render-event"));
                    },
                };
            },
        };
    }),
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to?.name && from?.name && to.name !== from.name) {
            return { x: 0, y: 0 };
        }
    },
});

const trackRoute = debounce(1000, false, (route) => {
    store.dispatch("user/pageVisit", { url: route.fullPath });
});

router.beforeEach(async (to, from, next) => {
    let user = null;
    user = await store.dispatch("user/getUser");

    if (to.meta.unauthorizedOnly && user) {
        //@V: next({ path: "/wb/category" });
        next({ path: "/dashboard" });
        return;
    }
    if (to.meta.authorizedOnly && !user) {
        next({
            name: "Login",
            params: {
                backRoute: to || undefined,
            },
        });
        return;
    }
    if (["Login", "Register", "RestorePassword"].includes(to.name) && from) {
        let overrideParams = false;
        const params = {
            ...to.params,
        };
        if (!to.params.forceBackRoute && from.params.forceBackRoute) {
            params.forceBackRoute = from.params.forceBackRoute;
            overrideParams = true;
        }
        if (!to.params.backRoute) {
            params.backRoute = from.params.backRoute || from;
            overrideParams = true;
        }
        if (overrideParams) {
            next({
                ...to,
                params,
            });
            return;
        }
    }
    if (user) {
        trackRoute(to);
    }
    next();
});

export default router;
