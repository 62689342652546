import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        listCategoriesForSearch: [],
        pageKey: 0,
        modals: [],
        prerender: !!window.PRERENDER,
        chineseDictionary:
            JSON.parse(localStorage.getItem("chineseDictionary")) || null,
    },
    mutations: {
        SET_CATEGORIES_FOR_SEARCH(state, data) {
            state.listCategoriesForSearch = data;
        },
    },
    getters: {
        CATEGORIES_FOR_SEARCH: (state) => {
            return state.listCategoriesForSearch;
        },
    },
    modules: {
        user: require("./user").default,
        category: require("./category").default,
        brand: require("./brand").default,
        seller: require("./seller").default,
        common: require("./common").default,
        product: require("./product").default,
        table: require("./table").default,
        list: require("./list").default,
        listM: require("./listM").default,
        rating: require("./rating").default,
        feedback: require("./feedback").default,
        partner: require("./partner").default,
        billing: require("./billing").default,
        connections: require("./connections").default,
        seo: require("./seo").default,
        reviewManagement: require("./reviewManagement").default,
    },
});
