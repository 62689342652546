import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
dayjs.extend(customParseFormat);
dayjs.extend(utc);

const FORMAT = "YYYY-MM-DD";
const FORMAT_DT = "YYYY-MM-DD HH:mm:ss";
const VISIBLE_FORMAT = "DD.MM.YYYY";
const VISIBLE_FORMAT_DT = "DD.MM.YYYY HH:mm";
export { dayjs, FORMAT, FORMAT_DT, VISIBLE_FORMAT, VISIBLE_FORMAT_DT };

export function parseDate(date, format = FORMAT) {
    return dayjs(date, format);
}

export function formatDate(dayjsObject, format = FORMAT) {
    return dayjsObject.format(format);
}

export function dateRangeDays(date1, date2, format = FORMAT) {
    return dayjs(date2, format).diff(dayjs(date1, format), "day") + 1;
}

export function dateRangeDaysArray(date1, date2, format = FORMAT) {
    const startDate = dayjs(date1, format);
    const daysCount = dateRangeDays(date1, date2, format);
    const result = [];
    for (let day = 0; day < daysCount; day++) {
        const dateKey = startDate.clone().add(day, "day").format(format);
        result.push(dateKey);
    }
    return result;
}

export function dateFormat(v) {
    const date = dayjs(v, FORMAT);
    if (!date.isValid()) {
        return "";
    }
    return date.format("DD.MM.YYYY");
}

// Вывод даты, на входе utc, на выходе - локальное время
export function dateTimeFormat(v) {
    const date = dayjs.utc(v, FORMAT_DT);
    if (!date.isValid()) {
        return "";
    }
    return date.local().format("DD.MM.YYYY HH:mm");
}

export function dateTimeLocalFormat(v) {
    const date = dayjs(v, FORMAT_DT);
    if (!date.isValid()) {
        return "";
    }
    return date.format("DD.MM.YYYY HH:mm");
}

export function today(formatString = FORMAT) {
    return dayjs().format(formatString);
}

export function dateRangeFrom(daysSkip, days, formatString = FORMAT) {
    return [
        dayjs()
            .subtract(days + daysSkip, "day")
            .format(formatString),
        dayjs().subtract(daysSkip, "day").format(formatString),
    ];
}

export function dateRangeFromNow(days, formatString = FORMAT) {
    return [
        dayjs()
            .subtract(days - 1, "day")
            .format(formatString),
        dayjs().format(formatString),
    ];
}
export function dateRangeFromDate(date, days, formatString = FORMAT) {
    const end = dayjs(date, formatString);
    return [
        end.subtract(days - 1, "day").format(formatString),
        end.format(formatString),
    ];
}

export function dateRangeBoundaries(
    baseRange,
    boundariesRange,
    formatString = FORMAT
) {
    const boundaries = boundariesRange.map((i) =>
        i ? dayjs(i, formatString) : i
    );
    return baseRange.map((i) => {
        if (!i) {
            return i;
        }
        const date = dayjs(i, formatString);
        if (boundaries[0] && date < boundaries[0]) {
            return boundariesRange[0];
        }
        if (boundaries[1] && date > boundaries[1]) {
            return boundariesRange[1];
        }
        return i;
    });
}
export function dateRangeUnsafe(date, date2, formatString = FORMAT) {
    const dateParsed = dayjs(date, formatString);
    const date2Parsed = dayjs(date2, formatString);
    return [
        date && dateParsed.isValid() ? dateParsed.format(formatString) : null,
        date2 && date2Parsed.isValid()
            ? date2Parsed.format(formatString)
            : null,
    ];
}
