import Vue from "vue";
export default {
    namespaced: true,
    actions: {
        async getSeller(context, { s_id, mp, date, date2, fbs }) {
            const { data } = await Vue.api.get("/getSeller", {
                params: {
                    mp,
                    date,
                    date2,
                    s_id,
                    fbs,
                },
            });
            return data;
        },
        async getSellerByName(context, { query, mp }) {
            const { data } = await Vue.api.get("/getSellerByName", {
                params: {
                    query,
                    mp,
                },
            });
            return data;
        },
        async sellerOverviewAll(context, { mp, s_id, date, date2, fbs }) {
            const { data } = await Vue.api.get("/sellerOverviewAll", {
                params: {
                    mp,
                    s_id,
                    date,
                    date2,
                    fbs,
                },
            });
            return data;
        },
        async getSellerProducts(context, queryParams) {
            const { mp, s_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getSellerProducts", {
                params: {
                    mp,
                    s_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getSellerBrands(context, queryParams) {
            const { mp, s_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getSellerBrands", {
                params: {
                    mp,
                    s_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getSellerCategories(context, queryParams) {
            const { mp, s_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getSellerCategories", {
                params: {
                    mp,
                    s_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getSellerSegments(context, queryParams) {
            const { mp, s_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getSellerSegments", {
                params: {
                    mp,
                    s_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
    },
};
