//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingLayout from "./layouts/LoadingLayout.vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import EmptyLayout from "./layouts/EmptyLayout.vue";
export default {
  components: {
    DefaultLayout,
    EmptyLayout,
    LoadingLayout
  },
  meta: {
    titleTemplate: titleChunk => {
      let T = process.env.VUE_APP_TITLE || "SalesFinder";
      return titleChunk ? `${T} - ${titleChunk}` : T;
    }
  },
  data() {
    return {
      modalsIncrement: 0,
      modals: []
    };
  },
  created() {
    this.loadChineseDictionary();
    const generateModalOpener = method => async (componentArg, options) => {
      const component = (await componentArg()).default;
      const id = this.modalsIncrement++;
      this.modals.push({
        component,
        id
      });
      await this.$nextTick();
      if (this.$refs[`modal-${id}`]) {
        return this.$refs[`modal-${id}`][0][method](options);
      }
    };
    this.$modal.onOpen = generateModalOpener("open");
    this.$modal.onProcess = generateModalOpener("process");
    if (this.$store.state.user.outOfLimit) {
      this.$modal.open(() => import("@/components/modals/access/ModalOutOfLimitLogout"), {
        limit: this.$store.state.user.outOfLimit
      });
    }
  },
  watch: {
    $route() {
      this.modals = [];
    }
  },
  computed: {
    layout() {
      if (!this.$route.name) {
        return "loading-layout";
      }
      return this.$route.meta.layout || "default-layout";
    }
  },
  methods: {
    parseCSV(csv) {
      // Split the CSV into lines
      let lines = csv.split("\n");
      let obj = {};

      // Loop through each line (starting from index 1 as index 0 is headers)
      for (let i = 0; i < lines.length; i++) {
        let currentLine = lines[i].split("\t");
        obj[currentLine[0]] = currentLine[1];
      }
      return obj;
    },
    loadChineseDictionary() {
      fetch("Category&SomwWords-china.csv").then(response => response.text()).then(csvData => {
        var parsedData = this.parseCSV(csvData);
        console.log(parsedData);
        localStorage.setItem("chineseDictionary", JSON.stringify(parsedData));
      }).catch(error => console.error("Error Chinese Dictionary file:", error));
    }
  }
};