//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PluginBtn from "./PluginBtn.vue";
export default {
  components: {
    PluginBtn
  },
  data() {
    return {
      collapsed: false,
      sidebarOpen: true,
      realMenu: [],
      openedParts: [],
      menu: [{
        icon: "dashboard.svg",
        title: "首页",
        route: "/dashboard"
      }, {
        icon: "wb.svg",
        title: "Wildberries",
        route: "",
        part: "wb"
      }, {
        icon: "",
        title: "产品类别",
        route: "/wb/category/",
        part: "wb"
      }, {
        icon: "",
        title: "产品",
        route: "/wb/product/",
        part: "wb"
      }, {
        icon: "",
        title: "品牌",
        route: "/wb/brand/",
        part: "wb"
      }, {
        icon: "",
        title: "卖家",
        route: "/wb/seller/",
        part: "wb"
      }, {
        icon: "",
        title: "排行榜",
        route: "/wb/ratings/",
        part: "wb"
        /*
        //         preventNavigation: () => {
        //         return !!this.$access.check('ratings')
        //   },
        //   click: (e) => {
        //       const accessMessage = this.$access.check('ratings')
        //       if (accessMessage) {
        //           alert(accessMessage)
        //           e.preventDefault()
        //       }
        //     }
        */
      }, {
        icon: "ozon.svg",
        title: "OZON",
        route: "",
        part: "ozon"
      }, {
        icon: "",
        title: "产品类别",
        route: "/ozon/category/",
        part: "ozon"
      }, {
        icon: "",
        title: "产品",
        route: "/ozon/product/",
        part: "ozon"
      }, {
        icon: "",
        title: "品牌",
        route: "/ozon/brand/",
        part: "ozon"
      }, {
        icon: "",
        title: "卖家",
        route: "/ozon/seller/",
        part: "ozon"
      }, {
        icon: "",
        title: "排行榜",
        route: "/ozon/ratings/",
        part: "ozon"
        /*
        //         preventNavigation: () => {
        //       return !!this.$access.check('ratings')
        //   },
        //   click: (e) => {
        //       const accessMessage = this.$access.check('ratings')
        //       if (accessMessage) {
        //           alert(accessMessage)
        //           e.preventDefault()
        //       }
        //   }
        */
      },
      /*
      //     {
      
      //     icon: 'compare.svg',
      //     title: 'Сравнение',
      //     route: '/'
      // },
      */
      {
        icon: "lists.svg",
        title: "我的清单",
        route: "/lists"
        /*
        //         preventNavigation: () => {
        //       return !!this.$access.check('lists')
        //   },
        //   click: (e) => {
        //       const accessMessage = this.$access.check('lists')
        //       if (accessMessage) {
        //           alert(accessMessage)
        //           e.preventDefault()
        //       }
        //   }
        */
      }, {
        icon: "seo.svg",
        title: "搜索引擎优化分析",
        route: "",
        part: "seo"
      }, {
        icon: "",
        title: "排名追踪",
        route: "/position-monitoring",
        part: "seo"
        // preventNavigation: () => true
      }, {
        icon: "",
        part: "seo",
        title: "搜索词挑选",
        route: "/queries-selection"

        //// title: "查询选择(即将推出)",
        //// route: "#",
        //// preventNavigation: () => true,
      }, {
        icon: "",
        title: "搜索结果分析 (即将推出)",
        // route: "/issuance-analysis",
        route: "#",
        part: "seo",
        preventNavigation: () => true
      }, {
        icon: "monitoring.svg",
        title: "变动监控",
        route: "/listMs"
      }, {
        icon: "shops.svg",
        title: "我的店铺",
        route: "",
        part: "connections"
      }, {
        icon: "",
        title: "概述",
        route: "/highlights",
        part: "connections"
      }, {
        icon: "",
        title: "订单",
        route: "/orders",
        part: "connections"
      }, {
        icon: "",
        title: "销售",
        route: "/sales",
        part: "connections"
      }, {
        icon: "",
        title: "销售地区分布",
        route: "/sales-geography",
        part: "connections"
      }, {
        icon: "",
        title: "产品",
        route: "/products",
        part: "connections"
      }, {
        icon: "",
        title: "仓库",
        route: "/storage",
        part: "connections"
      }, {
        icon: "",
        title: "退货",
        route: "/Returns",
        part: "connections"
      }, {
        icon: "",
        title: "ABC等级分析",
        route: "/abcAnalysis",
        part: "connections"
      }, {
        icon: "",
        title: "交货计算",
        route: "/delivery-calculation",
        part: "connections"
      }, {
        icon: "",
        title: "时期比较",
        route: "/compare",
        part: "connections"
      }, {
        icon: "",
        title: "财务分析",
        route: "/financial-analysis",
        part: "connections"
      }, {
        icon: "",
        title: "链接商店",
        route: "/connections",
        part: "connections"
      }, {
        icon: "review-management.svg",
        title: "反馈管理",
        route: "",
        part: "review-management",
        new: true,
        labelColor: "green"
      }, {
        icon: "",
        title: "概述",
        route: "/review-management-highlights",
        part: "review-management"
      }, {
        icon: "",
        title: "回应方案",
        route: "/review-management-scripts",
        part: "review-management"
      }, {
        icon: "",
        title: "未回复的反馈",
        route: "/review-management-reviews-new",
        part: "review-management"
      }, {
        icon: "",
        title: "已处理反馈",
        route: "/review-management-reviews-archive",
        part: "review-management"
      }, {
        icon: "",
        title: "按类别统计",
        route: "/review-management-categories",
        part: "review-management"
      }, {
        icon: "",
        title: "链接商店",
        route: "/review-management-connections",
        part: "review-management"
      },
      // {
      //     icon: "content-management.svg",
      //     title: "内容管理",
      //     href: "https://pim.salesfinder.ru/auth",
      //     labelColor: "green",
      // },
      // {
      //     icon: "sf-consulting.svg",
      //     title: "SF 咨询",
      //     href: "https://help.salesfinder.cn/consulting",
      //     labelColor: "green",
      // },
      {
        icon: "about.svg",
        title: "关于服务",
        route: "",
        part: "about"
      },
      // {
      //     icon: "",
      //     title: "服务特色",
      //     route: "/features",
      //     part: "about",
      // },
      {
        icon: "",
        title: "价格",
        route: "/pricing",
        part: "about"
      }, {
        icon: "",
        title: "联系方式",
        route: "/contact",
        part: "about"
      },
      // {
      //     icon: "",
      //     title: "培训材料",
      //     href: "https://help.salesfinder.cn/",
      //     part: "about",
      // },
      {
        icon: "",
        title: "合作伙伴计划",
        route: "/partner",
        part: "about"
      }].map((i, id) => ({
        ...i,
        id
      }))
    };
  },
  created() {
    // this.updateMenu()
    this.checkConnectionStatus();
    this.setCalendar();
    this.checkPart();
    this.collapsed = !this.sidebarOpen;
  },
  methods: {
    async checkConnectionStatus() {
      try {
        await this.$store.dispatch("connections/setHasTokens");
      } catch (e) {
        console.error(e);
      }
      try {
        await this.$store.dispatch("reviewManagement/setHasTokens");
      } catch (e) {
        console.error(e);
      }
    },
    setCalendar() {
      this.$store.dispatch("connections/setCalendarBoundaries");
      this.$store.dispatch("connections/setCalendar");
      this.$store.dispatch("reviewManagement/setCalendarBoundaries");
      this.$store.dispatch("reviewManagement/setCalendar");
    },
    // updateMenu() {
    //   this.$store.state.connections.loading ?
    //       this.realMenu = this.menu.filter(item => (item.id < 15 || item.id > 23)) :
    //       this.realMenu = this.menu
    // },
    toggleSidebar() {
      this.collapsed = this.sidebarOpen;
      this.sidebarOpen = !this.sidebarOpen;
      this.$store.dispatch("user/setSetting", {
        name: "sidebar",
        value: this.sidebarOpen
      });
    },
    toggleSidebarPart(name) {
      if (this.openedParts.includes(name)) {
        this.openedParts = this.openedParts.filter(item => item !== name);
      } else {
        this.openedParts.push(name);
      }
    },
    checkPart() {
      var _this$menu$find;
      this.sidebarOpen = this.$store.state.user.settings["sidebar"] === false ? false : true;
      const part = (_this$menu$find = this.menu.find(item => {
        if (!item.route || !this.$route.path) {
          return false;
        }
        const itemRoute = item.route.replace(/\/$/, "");
        const currentRoute = this.$route.path.replace(/\/$/, "");
        return itemRoute && currentRoute && currentRoute.startsWith(itemRoute);
      })) === null || _this$menu$find === void 0 ? void 0 : _this$menu$find.part;
      if (part) {
        this.openSidebarPart(part);
      }
    },
    openSidebarPart(name) {
      if (!this.openedParts.includes(name)) {
        this.openedParts.push(name);
      }
    },
    mouseEnterSidebar() {
      if (!this.sidebarOpen) {
        this.collapsed = false;
      }
    },
    mouseLeaveSidebar() {
      if (!this.sidebarOpen) {
        this.collapsed = true;
      }
    }
  },
  watch: {
    $route() {
      this.checkPart();
    }
    // '$store.state.connections.loading': function () {
    //   this.updateMenu()
    // },
  }
};