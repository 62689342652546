import Vue from "vue";
import { getMarketplacesListArray } from "@/utils/enums";
import { dateRangeBoundaries, dateRangeFromDate, today } from "@/utils/dates";
// import { jsonToFormData } from "@/utils/newModule";

const select_marketplace_items = [...getMarketplacesListArray()];

export default {
    namespaced: true,
    state: {
        hasTokens: false,
        hasValidTokens: false,
        hasInitialReadyTokens: true,
        loading: false,
        loaded: true,
        tokens: [],
        selectedTokens:
            JSON.parse(
                localStorage.getItem("reviewManagementSelectedTokens")
            ) || [],
        dates: [],
        calendar: null,
        calendarBoundaries: [],
        marketplace:
            JSON.parse(localStorage.getItem("reviewManagementMp")) ||
            select_marketplace_items[0],
    },
    mutations: {
        SET_TOKENS(state, data) {
            state.tokens = data;
        },
        SET_HAS_TOKENS(state, data) {
            state.hasTokens = data;
        },
        SET_HAS_VALID_TOKENS(state, data) {
            state.hasValidTokens = data;
        },
        SET_LOADING(state, data) {
            state.loading = data;
            if (!data) {
                state.loaded = true;
            }
        },
        SET_HAS_INITIAL_READY_TOKENS(state, data) {
            state.hasInitialReadyTokens = data;
        },
        SET_CALENDAR(state, data) {
            state.calendar = data;
        },
        SET_DATES(state, data) {
            state.dates = data;
        },
        SET_CALENDAR_BOUNDARIES(state, data) {
            state.calendarBoundaries = data;
        },
        SET_SELECTED_TOKENS(state, data) {
            state.selectedTokens = data;
        },
        SET_MARKETPLACE(state, data) {
            state.marketplace = data;
        },
    },
    actions: {
        async setHasTokens({ dispatch }) {
            await dispatch("getTokens", {});
        },
        setMarketplace({ commit }, data) {
            commit("SET_MARKETPLACE", data);
        },
        setSelectedTokens({ commit }, data) {
            localStorage.setItem(
                "reviewManagementSelectedTokens",
                JSON.stringify(data)
            );
            commit("SET_SELECTED_TOKENS", data);
        },
        setCalendar({ commit, state }) {
            if (state.calendar === null) {
                if (!state.calendarBoundaries) {
                    commit("SET_CALENDAR", null);
                    return;
                }
                const lastAvailableDate =
                    state.calendarBoundaries?.[1] || today();
                const monthRange = dateRangeFromDate(lastAvailableDate, 30);
                // const queryRange = dateRangeUnsafe(this.$route.query?.date, this.$route.query?.date2)
                commit(
                    "SET_CALENDAR",
                    dateRangeBoundaries(
                        [monthRange[0], monthRange[1]],
                        state.calendarBoundaries
                    )
                );
            }
        },
        updateCalendar({ commit }, data) {
            commit("SET_CALENDAR", data);
        },
        setDates({ commit }, data) {
            commit("SET_DATES", data);
        },
        setCalendarBoundaries({ commit, state }) {
            if (state.calendarBoundaries.length < 2) {
                const date2 = new Date();

                date2.setDate(date2.getDate());
                const calendarBoundaries = [
                    "2022-03-26",
                    date2.toISOString().split("T")[0],
                ];
                commit("SET_CALENDAR_BOUNDARIES", calendarBoundaries);
            }
        },
        /**
         * Загружает токены пользователя и задает state-свойства доступности
         */
        async getTokens({ commit, dispatch, state }) {
            commit("SET_LOADING", true);
            const { data } = await Vue.api.get(
                "/service/seller/wb/feedback/token"
            );
            const tokens = data?.data;
            commit("SET_TOKENS", tokens);
            const filteredSelectedTokens = state.selectedTokens.filter(
                (token) => {
                    return tokens.some((item) => item.id === token.id);
                }
            );
            dispatch("setSelectedTokens", filteredSelectedTokens);
            const hasTokens = !!data?.data.length;
            commit("SET_HAS_TOKENS", hasTokens);
            const hasValidTokens = data?.data.some((item) => {
                // return ![10, 20, 40, 50].includes(item.status);
                return item.status === 30;
            });
            commit("SET_HAS_VALID_TOKENS", hasValidTokens);
            const hasInitialReadyTokens = data?.data.some((item) => {
                return item.initial_ready === 1;
            });
            commit("SET_HAS_INITIAL_READY_TOKENS", hasInitialReadyTokens);
            commit("SET_LOADING", false);
            return data;
        },
        // Выборка по токенам пользователя
        async queryTokens({ dispatch }) {
            const { data } = await Vue.api.get(
                "/service/seller/wb/feedback/token"
            );
            await dispatch("getTokens");
            return data;
        },
        async createToken({ dispatch }, { token, name }) {
            const { data } = await Vue.api.post(
                "/service/seller/wb/feedback/token",
                {
                    token,
                    name,
                }
            );
            console.log("created token");
            await dispatch("getTokens");
            return data;
        },

        async updateToken(ctx, { id, name, token }) {
            const { data } = await Vue.api.patch(
                `/service/seller/wb/feedback/token/${id}`,
                {
                    name,
                    token,
                }
            );
            return data;
        },
        async removeToken({ dispatch }, { id }) {
            const { data } = await Vue.api.delete(
                `/service/seller/wb/feedback/token/${id}`
            );
            await dispatch("getTokens");
            return data;
        },
        async getWbHighlights(context, { date, date2, tokens }) {
            let params = {
                date_from: date,
                date_to: date2,
                tokens,
            };
            const { data } = await Vue.api.get(
                "/service/seller/wb/feedback/stats",
                {
                    params,
                }
            );
            return data.data;
        },
        async getCategories(context, { tokens }) {
            const { data } = await Vue.api.get(
                `/service/seller/wb/feedback/token/${tokens[0]}/categories`
            );
            return data;
        },
        async getReviewsActive(
            context,
            { page = 1, page_size = 10, date, date2, tokens, sort, sort_dir }
        ) {
            switch (sort) {
                case "sku":
                    sort = "product.sku";
                    break;
                case "productName":
                    sort = "product.name";
                    break;
                case "script":
                    sort = "answer.script";
                    break;
                case "answerDate":
                    sort = "answer.created_at";
                    break;
                case "check":
                    sort = "approve_status";
                    break;
                case "status":
                    sort = "send_status";
                    break;
            }
            let params = {
                page,
                page_size,
                date_from: date,
                date_to: date2,
                tokens,
                sort_dir,
                sort,
            };
            const { data } = await Vue.api.get(
                `/service/seller/wb/feedback/active`,
                {
                    params,
                }
            );
            return data;
        },
        async getReviewsArchive(
            context,
            { page = 1, page_size = 10, date, date2, tokens }
        ) {
            let params = {
                page,
                page_size,
                date_from: date,
                date_to: date2,
                tokens,
            };
            const { data } = await Vue.api.get(
                `/service/seller/wb/feedback/archived`,
                {
                    params,
                }
            );
            return data;
        },
        async getScripts(
            context,
            { page = 1, page_size = 10, date, date2, tokens }
        ) {
            let params = {
                page,
                page_size,
                date_from: date,
                date_to: date2,
                tokens,
            };
            const { data } = await Vue.api.get(
                `/service/seller/wb/feedback/script`,
                {
                    params,
                }
            );
            return data;
        },
        async getScript(context, id) {
            const { data } = await Vue.api.get(
                `/service/seller/wb/feedback/script/${id}`
            );
            return data.data;
        },
        async addScript(context, body) {
            const { data } = await Vue.api.post(
                "/service/seller/wb/feedback/script",
                body
            );
            return data;
        },
        async updateScript(context, { id, body }) {
            const { data } = await Vue.api.put(
                `/service/seller/wb/feedback/script/${id}`,
                body
            );
            return data;
        },
        async removeScript(context, { id }) {
            const { data } = await Vue.api.delete(
                `/service/seller/wb/feedback/script/${id}`
            );
            return data;
        },
        async checkScript(context, template) {
            const { data } = await Vue.api.post(
                "/service/seller/wb/feedback/script/mock",
                { template }
            );
            return data.data;
        },
        async toggleScript(context, id) {
            const { data } = await Vue.api.post(
                `/service/seller/wb/feedback/script/${id}/toggle`
            );
            return data;
        },
        async updateAnswer(context, { id, answer }) {
            const { data } = await Vue.api.post(
                `/service/seller/wb/feedback/answer/${id}/approve`,
                {
                    answer_text: answer,
                }
            );
            return data;
        },
        async multipleApprove(context, answers) {
            const { data } = await Vue.api.post(
                `/service/seller/wb/feedback/answer/approve`,
                {
                    answers,
                }
            );
            return data;
        },
    },
};
