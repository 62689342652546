import Vue from "vue";
import App from "./App.vue";

import "./assets/scss/style.scss";
import { default as router } from "./router"; //@V:2022-06-24

import store from "./store";

import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
Vue.component("vue-custom-scrollbar", vueCustomScrollbar);

import apiPlugin from "./plugins/api";
Vue.use(apiPlugin);

import modalPlugin from "./plugins/modal";
Vue.use(modalPlugin);

import accessPlugin from "./plugins/access";
Vue.use(accessPlugin);

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import VueMq from "vue-mq";
Vue.use(VueMq, {
    breakpoints: {
        mobile: 450,
        tablet: 900,
        laptop: 1250,
        desktop: Infinity,
    },
});

import { FadeTransition } from "vue2-transitions";
Vue.component("FadeTransition", FadeTransition);

import VueMeta from "vue-meta";
Vue.use(VueMeta, {
    keyName: "meta",
    refreshOnceOnNavigation: true,
});

//!&: 2022-06-07 : Yandex.Metrika : https://www.npmjs.com/package/vue-yandex-metrika
import VueYandexMetrika from "vue-yandex-metrika";
Vue.use(VueYandexMetrika, {
    id: 96876049,
    router: router,
    env: process.env.NODE_ENV,
    //// other options
});

import telemetryPlugin from "./plugins/telemetry";
Vue.use(telemetryPlugin);

/* //! не  смог поставить на .27 - много битых зависимостей - нужно разбираться
//!&: 2022-06-10 : Google Analytics : https://matteo-gabriele.gitbook.io/vue-gtag/
// npm add vue-gtag@0.1.0 ## для vue2
import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
    config: { id: "G-272D7V4NHN" }
})
*/

import AutoReload from '@/plugins/AutoReload';

Vue.config.productionTip = false;

Vue.component("client-only", {
    render: function () {
        return window.PRERENDER ? null : this.$slots.default;
    },
});

//@V: 2022-06-24
const rootInstance = new Vue({
    router,
    store,

    //&:
    created() {
        Vue.use(AutoReload, {
            config: {
                // модуль включен
                Enabled: true,
                //// ежеминутное обновление
                //// CheckInterval: 60,
                Notification: true,
            },
            router: this.$router,
            vm: this,
        });
    },

    render: (h) => h(App),
    mounted() {},
});
// We use this trick (wrapping in onready hook) to avoid app mounting
// with empty initial route (before real first route was loaded)
// In this way it will not override html (prerendered) until real route will be loaded
router.onReady(() => {
    rootInstance.$mount("#app");
});
