export function jsonToFormData(json) {
    let form_data = new FormData();
    for (let i in json) {
        if (Array.isArray(json[i]) && json[i].length > 1) {
            json[i].forEach((item) => {
                form_data.append(i, item);
            });
        } else {
            form_data.append(i, json[i]);
        }
    }
    return form_data;
}

export function filtersToObject(filters) {
    let array = [];
    let counter = 5;
    Object.keys(filters).forEach((item, index) => {
        if (item[0] === "f") {
            if (item.substring(item.length - 3) === "min") {
                array.push([
                    "filter[filters][" + counter + "][field]",
                    item.substring(2).slice(0, -4),
                ]);
                array.push([
                    "filter[filters][" + counter + "][operator]",
                    "gt",
                ]);
            } else if (item.substring(item.length - 3) === "max") {
                array.push([
                    "filter[filters][" + counter + "][field]",
                    item.substring(2).slice(0, -4),
                ]);
                array.push([
                    "filter[filters][" + counter + "][operator]",
                    "lt",
                ]);
            } else {
                array.push([
                    "filter[filters][" + counter + "][field]",
                    item.substring(2),
                ]);
                array.push([
                    "filter[filters][" + counter + "][operator]",
                    "contains",
                ]);
            }
            array.push([
                "filter[filters][" + counter + "][value]",
                Object.values(filters)[index],
            ]);
            counter++;
        }
    });
    const object = {};
    array.forEach((item) => {
        object[item[0]] = item[1];
    });
    return object;
}

export async function tableLoaderCreator(
    columns,
    dataSource,
    dispatch,
    query,
    reportSettings,
    wbRequest,
    ozRequest,
    wbArgs,
    ozArgs
) {
    let result = {};
    if (query.action === "export") {
        //query.page_size = 10000;
        query.page_size = 5000; // ~ max для wbCalcDelivery
        query.page = 1;
        let total = query.page_size + 1;

        //console.log('newModule.80: query.page_size=', query.page_size);

        let resultPart = {};
        while (query.page_size * (query.page - 1) < total) {
            //console.log('newModule.85: dataSource=', dataSource);
            //console.log('newModule.86: query.page=', query.page);

            if (dataSource === "Wildberries") {
                resultPart = await dispatch(wbRequest, {
                    ...query,
                    ...reportSettings,
                    ...wbArgs,
                });
            } else {
                ////if (dataSource === "OZON") {
                resultPart = await dispatch(ozRequest, {
                    ...query,
                    ...reportSettings,
                    ...ozArgs,
                });
            }

            if (query.page === 1) {
                total = resultPart.total;
                result = resultPart;
            } else {
                result.data = [...result.data, ...resultPart.data];
            }

            query.page++;
        }
    } else {
        if (dataSource === "Wildberries") {
            result = await dispatch(wbRequest, {
                ...query,
                ...reportSettings,
                ...wbArgs,
            });
        } else {
            ////if (dataSource === "OZON") {
            result = await dispatch(ozRequest, {
                ...query,
                ...reportSettings,
                ...ozArgs,
            });
        }
    }
    if (query.action === "export") {
        let data = result.data;

        const titleNames = columns.map((columnRaw) => {
            const { name, title } = columnRaw;
            return { name, title };
        });

        let option = {};

        const dataset = data.map((el) => {
            let object = {};
            titleNames.forEach((item) => {
                object[item.name] = el[item.name];
            });
            return object;
        });

        option.type = "excel";

        option.datas = [
            {
                sheetData: dataset,
                sheetName: "Page 1",
                sheetFilter: Object.keys(dataset[0]),
                sheetHeader: titleNames.map((item) => item.title),
            },
        ];

        let params = [
            {
                title: "Маркетплейс",
                value: reportSettings.marketplace,
            },
            {
                title: "Токены",
                value: reportSettings.tokens,
            },
            {
                title: "Когда планируется поставка",
                value: reportSettings.date_delivery,
            },
            {
                title: "На сколько дней заполняем склад",
                value: reportSettings.count_days,
            },
            {
                title: "Период расчета потребности",
                value: reportSettings.period,
            },
            {
                title: "Склады",
                value: reportSettings.storesNames,
            },
            {
                title: "Коэффициент",
                value: reportSettings.ratio,
            },
        ];

        if (reportSettings.type === "DeliveryCalculation") {
            option.datas[1] = {
                sheetData: params,
                sheetName: "Параметры расчета",
            };
        }

        return option;

        // OLD CSV EXPORT

        // const titles = columns.map(item => item.title).toString()
        // const names = columns.map(item => item.name)
        // let values = ""
        // result.data.forEach(item => {
        //   const keys = Object.keys(item)
        //   const arr = []
        //   keys.forEach(i => {
        //     if (names.includes(i)) {
        //       arr.push([i, String(item[i]).indexOf(',') > -1 ? `"${item[i]}"` : item[i]])
        //     }
        //   })
        //   arr.sort((a, b) => names.indexOf(a[0]) - names.indexOf(b[0]));
        //   let str = arr.map(i => i[1]).toString() + ","
        //   str = str.substr(0, str.length - 1)
        //   values += str + '\n'
        // })
        // result = titles + "\n" + values
    } else {
        result = {
            items: [...result.data].map((item) => {
                Object.keys(item).forEach((key) => {
                    if (item[key] === -1) {
                        item[key] = "-1";
                    } else if (item[key] === "" || item[key] === null) {
                        item[key] = -1;
                    }
                });
                return {
                    ...item,
                    image:
                        dataSource === "Wildberries"
                            ? `https://salesfinder.cn/api/getProductImage?mp=wb&sku=${
                                  item.nmid || item.nmId
                              }`
                            : // OZON
                              `https://salesfinder.cn/api/getProductImage?mp=ozon&sku=${
                                  item.sku || item.fbo_sku
                              }`,
                    last_event: item.events,
                };
            }),
            total: result.total,
            success: result.success,
            stores: result.stores ? result.stores : null,
            message: result.message,
        };
    }
    return result;
}

export function numWord(value, words) {
    value = Math.abs(value) % 100;

    var num = value % 10;

    if (value > 10 && value < 20) return words[2];

    if (num > 1 && num < 5) return words[1];

    if (num === 1) return words[0];

    return words[2];
}
