//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SlideYUpTransition } from "vue2-transitions";
export default {
  props: {
    white: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "bottom-right",
      validate: v => ["bottom-right", "top-right"].includes(v)
    }
  },
  data() {
    return {
      userPopup: false
    };
  },
  methods: {
    async onSignOut() {
      //последствия promocode
      window.sessionStorage.removeItem("promocode:code");
      window.sessionStorage.removeItem("promocode:show");
      window.sessionStorage.removeItem("promocode.type");
      window.sessionStorage.removeItem("promocode.class");
      window.sessionStorage.removeItem("promocode.reason");

      //@V:
      await this.$store.dispatch("user/signOut");
      const loginRoute = this.$router.resolve("/login");
      console.log(loginRoute);
      window.document.location.assign(loginRoute.href);
    }
  },
  created() {
    this.$store.dispatch("user/getUser");
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  },
  components: {
    SlideYUpTransition
  }
};